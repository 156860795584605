import * as React from "react";
import { graphql, PageProps } from "gatsby";

import RecipesTemplate from "@components/recipes/recipePageTemplate";
import { BreadcrumbPageContext } from "@util/types";
import { Query } from "@graphql-types";

interface Props extends PageProps {
  pageContext: BreadcrumbPageContext;
  data: Query;
}

const RecipesPage = ({ pageContext, data }: Props) => {
  const { allSanityRecipe, allSanityRecipeTag, sanityRecipeLandingPage } = data;
  return (
    <RecipesTemplate
      pageContext={pageContext}
      allSanityRecipe={allSanityRecipe}
      allSanityRecipeTag={allSanityRecipeTag}
      sanityRecipeLandingPage={sanityRecipeLandingPage}
      iban={pageContext.iban}
    />
  );
};

export default RecipesPage;

export const query = graphql`
  query RecipesQuery($iban: String) {
    allSanityRecipeTag(
      sort: {fields: orderRank, order: ASC }
      filter: { main: { regions: { elemMatch: { iban: { eq: $iban } } } } }
    ) {
      ...sanityRecipeTagConnection
    }
    allSanityRecipe(
      filter: { regions: { elemMatch: { iban: { eq: $iban } } } }
    ) {
      ...sanityRecipeConnection
    }
    sanityRecipeLandingPage(region: { iban: { eq: $iban } }) {
      hero {
        ...sanityHero
      }
      additionalHeros {
        ...sanityHero
      }
      seo {
        ...sanitySeo
      }
    }
  }
`;
